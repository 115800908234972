import { AnimatePresence, motion } from "framer-motion";

import "./PopUp.scss";
// import "./Adaptations.scss";
import { popup_animation } from "./animations";

import TriangleImage from "../../static/images/popup-triangle.svg";
import { useEffect, useState } from "react";

export default function PopUp({ title, desc }) {
    const [isActive, setIsActive] = useState(true);
    useEffect(() => {
        setTimeout(() => setIsActive(false), 5000);
    });
    const closeFunc = () => setIsActive(false);

    return (
        <AnimatePresence>
            {isActive && (
                <motion.div
                    className="pop-up"
                    {...popup_animation}
                    onClick={closeFunc}
                >
                    <div className="pop-up-box">
                        <div className="pop-up-box-title">
                            <span className="pop-up-box-title-text">
                                {title}
                            </span>
                        </div>
                        <div className="pop-up-box-desc">
                            <span className="pop-up-box-title-text">
                                {desc}
                            </span>
                        </div>
                    </div>
                    <div className="pop-up-triangle">
                        <img src={TriangleImage} alt="triangle" />
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}
