import { useContext } from "react";
import { useTranslation } from "react-i18next";

import "./SvgAreas.scss";

import { WithInfobox } from "../../../infobox/Infobox";
import LandInfo from "../../../infobox/land-info/LandInfo";
import BlockInfo from "../../../infobox/block-info/BlockInfo";
import { MainContext } from "../../../../pages/main/Main";

import ContactForm from "../../contact-form/ContactForm";

import unitSizeJSON from "../../../../pages/main/contents/zone-5/unit_sizes.json";

function SvgArea({
    Polygon,
    className,
    infobox,
    isClickableOnMobileInfobox = true,
    onClick,
}) {
    const [t] = useTranslation();
    const [changeContent, setModalContent] = useContext(MainContext);

    let title;
    let infoboxInner;
    let blockNumberOfUnits;
    let blockSize = 0;

    if (infobox?.type === "land_info" || infobox?.type === "block_info") {
        title = infobox.type + "_title";

        className = "zone-5-area";

        switch (infobox.status) {
            case "booked":
                className += " area-booked";
                break;
            case "sold":
                className += " area-sold";
                break;
            default:
                className += " area-available";
                break;
        }
    }
    if (!infobox?.hidden) {
        if (infobox?.type === "land_info") {
            infoboxInner = (
                <LandInfo
                    title={title}
                    index={infobox.index}
                    status={infobox.status}
                    electricTransformer={infobox.electricTransformer}
                    unitSize={infobox.unitSize}
                    block={infobox.block}
                />
            );
        } else if (infobox?.type === "block_info") {
            for (let unit of unitSizeJSON) {
                if (unit.block === infobox.index) {
                    blockSize += unit.unitSize;
                }
            }
            blockNumberOfUnits = unitSizeJSON.filter(
                (element) => element.block === infobox.index
            ).length;
            infoboxInner = (
                <BlockInfo
                    title={title}
                    index={infobox.index}
                    status={infobox.status}
                    blockSize={Math.round(blockSize)}
                />
            );
        }
    }

    if (onClick?.action) {
        switch (onClick.action) {
            case "open_contact_form":
                if (infobox?.type === "land_info") {
                    onClick = () => {
                        setModalContent(
                            <ContactForm
                                form_id="xyzgkeey"
                                title={`${t(title)} ${infobox.index}`}
                                form_type={infobox?.type.replace("_info", "")}
                                electricTransformer={
                                    infobox.electricTransformer
                                }
                                unitSize={infobox.unitSize}
                                block={infobox.block}
                                north={infobox.north}
                                east={infobox.east}
                                south={infobox.south}
                                west={infobox.west}
                                front1={infobox.front1}
                                front2={infobox.front2}
                                st_width_1={infobox.st_width_1}
                                st_width_2={infobox.st_width_2}
                            />
                        );
                    };
                } else if (infobox?.type === "block_info") {
                    onClick = () => {
                        setModalContent(
                            <ContactForm
                                form_id="xyzgkeey"
                                title={`${t(title)} ${infobox.index}`}
                                form_type={infobox?.type.replace("_info", "")}
                                blockSize={Math.round(blockSize)}
                                numberOfUnits={blockNumberOfUnits}
                            />
                        );
                    };
                }
                break;
            default:
                break;
        }
    }

    return infobox ? (
        <WithInfobox
            inner={infoboxInner}
            isClickableOnMobileInfobox={isClickableOnMobileInfobox}
            onClick={onClick}
        >
            <Polygon
                className={`svg-area ${className ? className : ""}`}
                onMouseDown={(e) => e.stopPropagation()}
                onMouseUp={(e) => e.stopPropagation()}
            />
        </WithInfobox>
    ) : (
        <Polygon
            className={`svg-area ${className ? className : ""}`}
            onMouseDown={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
            onClick={onClick}
        />
    );
}

export default function SvgAreas({ areas }) {
    return (
        <div className="svg-areas">
            <svg
                viewBox="0 0 1920 1080"
                version="1.0"
                preserveAspectRatio="xMidYMid slice"
                xmlns="http://www.w3.org/2000/svg"
            >
                {areas.map((area, i) => (
                    <SvgArea key={i} {...area} />
                ))}
            </svg>
        </div>
    );
}
